import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signOut as signOutFirebase } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'

const app = initializeApp({
  apiKey: "AIzaSyDRTSjI8HDXiLQSCOu2cY4j-ZVdFisqow8",
  authDomain: "ragam-ca.firebaseapp.com",
  databaseURL: "https://ragam-ca.firebaseio.com",
  projectId: "ragam-ca",
  storageBucket: "ragam-ca.appspot.com",
  messagingSenderId: "432438280307",
  appId: "1:432438280307:web:1a4a8bad9a917a83abea06",
  measurementId: "G-5FQNLKVSS4"
})

export const auth = getAuth(app)
export const db = getDatabase(app)
export const analytics = getAnalytics()

export function loggedIn() {
	return new Promise((resolve, reject) =>
		onAuthStateChanged(auth, (user) => {
			if (user) resolve(user)
			else reject(null)
		})
	)
}

export function signIn() {
	return new Promise((resolve, reject) => {
		loggedIn()
			.then(resolve)
			.catch(() => signInWithPopup(auth, new GoogleAuthProvider()).then(resolve).catch(reject))
	})
}

export function signOut() {
	return signOutFirebase(auth)
}
