import Nav from 'components/common/Nav'
import PageHeader from '../components/common/PageHeader'
import ContactUs from 'components/contact/ContactUs'
import RUReady from 'components/common/RUReady'
import Footer from 'components/common/Footer'

import telephone from 'assets/images/contact.png'

const Contact = () => {
	return (
		<>
			<Nav />
			<PageHeader title='Contact us' icon={telephone} />
			<ContactUs />
			<RUReady />
			<Footer />
		</>
	)
}

export default Contact
