import Nav from 'components/common/Nav'
import PageHeader from '../components/common/PageHeader'
import Footer from 'components/common/Footer'

import pen from 'assets/images/pen.png'
import RegisterClosed from 'components/regclosed/RegisterClosed'

const RegClosed = () => {
	return (
		<>
			<Nav />
			<PageHeader title='Register' icon={pen} />
            <RegisterClosed/>
			<Footer />
		</>
	)
}

export default RegClosed