import Nav from 'components/common/Nav'
import PageHeader from '../components/common/PageHeader'
import Leaderboard from '../components/leaderboard/Leaderboard'
import RUReady from 'components/common/RUReady'
import Footer from 'components/common/Footer'

import cup from 'assets/images/leaderboard.png'

const Contact = () => {
	return (
		<>
			<Nav />
			<PageHeader title='Leaderboard' icon={cup} />
			<Leaderboard />
			<RUReady />
			<Footer />
		</>
	)
}

export default Contact
