import { getDatabase, ref, set } from 'firebase/database'
import { v4 } from 'uuid'

const db = getDatabase()

export function submitContact(contact) {
	return new Promise((resolve, reject) => {
		const dbRef = ref(db, `contact/${v4()}`)
		set(dbRef, contact).then(resolve).catch(reject).catch(reject)
	})
}
