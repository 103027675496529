import React from 'react';
import closed from 'assets/images/reg-closed.png'
import { signOut } from 'lib/firebase'
import { useNavigate } from 'react-router-dom';

const RegisterClosed = () => {
  const navigate = useNavigate();
  function handleSignOut() {
    signOut()
      .then(() => navigate('/'))
      .catch(console.error)
  }
  return <>
    <div className='container'>
      <div className="closed-container">
        <img src={closed} alt="" className='closed-img' />
        <p className='regclosed-header'>Registration Closed</p>
        <p className='regclosed-info'>Contact us for any queries</p>
        <button type='submit' className='btn-secondary' onClick={handleSignOut}>
          Logout
        </button>
      </div>

      <div className='spacerv-md'></div>
      <div className='spacerv-sm'></div>
    </div>
  </>;
};

export default RegisterClosed;
