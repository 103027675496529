import { Link } from 'react-router-dom'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { HiOutlineExternalLink } from 'react-icons/hi'

import Logo from 'assets/images/ragam-white.svg'

export default function Footer() {
	return (
		<footer>
			<div className='container'>
				<div className='footer-top'>
					<Link to='/'>
						<img className='footer-logo' src={Logo} alt='Ragam 2023' />
					</Link>
					<div className='footer-links-wrapper'>
						<ul>
							<li>
								<p>Site</p>
							</li>
							<li>
								<Link to='/'>Home</Link>
							</li>
							<li>
								<Link to='/leaderboard'>Leaderboard</Link>
							</li>
							<li>
								<Link to='/contact'>Contact</Link>
							</li>
							{/* <li>
								<Link to='/team'>Team</Link>
							</li> */}
						</ul>
						<ul>
							<li>
								<p>Social</p>
							</li>
							<li>
								<a className='social-link' href='https://instagram.com/ragam_nitc' target='_blank' rel='noreferrer noopener'>
									<FaInstagram className='social-icon' />
									Instagram
								</a>
							</li>
							<li>
								<a className='social-link' href='https://www.facebook.com/Ragam.nitc/' target='_blank' rel='noreferrer noopener'>
									<FaFacebook className='social-icon' />
									Facebook
								</a>
							</li>
							<li>
								<a className='social-link' href='https://www.linkedin.com/company/ragam-nit-calicut' target='_blank' rel='noreferrer noopener'>
									<FaLinkedin className='social-icon' />
									Linkedin
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className='footer-bottom'>
					<p className='footer-copy'>&#169; Ragam 2023</p>
					<a className='footer-link' href='https://ragam.co.in/' target='_blank' rel='noreferrer noopener'>
						Ragam <HiOutlineExternalLink className='external-icon' />
					</a>
				</div>
			</div>
		</footer>
	)
}
