import benefitsImg from 'assets/images/benefits.svg'
import firstPrize from 'assets/images/first-prize.svg'
import secondPrize from 'assets/images/second-prize.svg'
import thirdPrize from 'assets/images/third-prize.svg'

export default function Benefits() {
	return (
		<div className='benefits'>
			<div className='benefits-image-container'>
				<div className='benefits-image-wrapper'>
					<h2 className='benefits-heading'>Benefits</h2>
					<img src={benefitsImg} alt='' className='benefits-image' />
				</div>
			</div>
			<div className='benefits-content'>
				<div className='prizes-wrapper'>
					<img src={firstPrize} alt='🥇 ₹10,000' />
					<img src={secondPrize} alt='🥈 ₹5,000' />
					<img src={thirdPrize} alt='🥉 ₹3,000' />
				</div>
				<div className='benefits-content-bottom'>
					<div className='other-prizes-wrapper'>
						<div className='other-prize-wrapper'>
							<p className='other-prize-position'>4th to 5th</p>
							<p className='other-prize'>₹2500</p>
						</div>
						<div className='other-prize-wrapper'>
							<p className='other-prize-position'>6th to 20th</p>
							<p className='other-prize'>₹1000</p>
						</div>
						<div className='other-prize-wrapper'>
							<p className='other-prize-position'>20th to 50th</p>
							<p className='other-prize' style={{fontSize:"25px"}}>Prizes worth ₹500</p>
						</div>
					</div>
					<div className='benefits-desc-wrapper'>
						<p>Special certificate for top 20 people</p>
						<p>Certificate for those who completed a certain tier (% of work)</p>
						<p>Extra benefits for excellence in work (Awarded by Ragam PRC)</p>
					</div>
				</div>
			</div>
		</div>
	)
}
