export default function PageHeader({ title, icon }) {
	return (
		<>
			<div className='page-header-bg'></div>
			<div className='page-heading-wrapper'>
				<img src={icon} alt='' className='page-heading-icon' />
				<h1 className='page-heading'>{title}</h1>
			</div>
		</>
	)
}
