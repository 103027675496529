import { getDatabase, ref, update } from 'firebase/database'

const db = getDatabase()

export function register(user, form) {
	return new Promise((resolve, reject) => {
		const dbRef = ref(db, `ambassadors/${user.uid}`)
		update(dbRef, form).then(resolve).catch(reject).catch(reject)
	})
}

// User filled fields: name, college, branch, year, experience, email, phone
export function validateUserDb(userDb) {
	if (!userDb) return { success: false, error: 'Invalid' }

	// const userFilledFields = ['name', 'college', 'branch', 'year', 'experience', 'email', 'phone']
	const requiredFields = ['name', 'college', 'experience', 'email', 'phone']
	let error = false
	for (let key of requiredFields)
		if (!userDb[key]) {
			error = true
			break
		}
	return !error ? { success: true } : { success: false, error: 'Fill the required fields' }
}
