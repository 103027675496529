import Nav from 'components/common/Nav'
import PageHeader from '../components/common/PageHeader'
import Dashboard from 'components/dashboard/Dashboard'
import Footer from 'components/common/Footer'

import dashboardImg from 'assets/images/dashboard.png'

export default function DashboardPage() {
	return (
		<>
			<Nav />
			<PageHeader title='Dashboard' icon={dashboardImg} />
			<Dashboard />
			<Footer />
		</>
	)
}
