import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'
import { signOut } from 'lib/firebase'

import referalImg from 'assets/images/referal.png'
import posterImg from 'assets/images/posters.png'
import feedbackImg from 'assets/images/feedback.png'
import whatsappImg from 'assets/images/whatsapp.png'
import doorImg from 'assets/images/door.png'

export default function SideNav({ userDb, points, onClose }) {
	const location = useLocation()
	const navigate = useNavigate()

	function handleSignOut() {
		signOut()
			.then(() => navigate('/'))
			.catch(console.error)
	}

	return (
		<div className='side-nav'>
			<div className='dashboard-menu-close-wrapper'>
				<IoMdClose className='dashboard-menu-icon' onClick={onClose} />
			</div>
			<div className='user-wrapper'>
				<div className='user-avatar' style={{ backgroundImage: `url(${userDb?.photo})` }}></div>
				<h3 className='user-name'>{userDb?.name || '--'}</h3>
				<p className='user-points'>{points || '--'} Points</p>
			</div>

			<div className='edit-profile-button-wrapper'>
				<Link to='/register?editprofile=true'>
					<button className='btn-outline'>Edit profile</button>
				</Link>
			</div>

			<ul className='side-nav-links'>
				<Link to='/dashboard/referrals' className={location.pathname === '/dashboard/referrals' ? 'active' : ''} onClick={onClose}>
					<img src={referalImg} alt='🎫' /> Referals
				</Link>
				<Link to='/dashboard/posters' className={location.pathname === '/dashboard/posters' ? 'active' : ''} onClick={onClose}>
					<img src={posterImg} alt='🌁' /> Posters
				</Link>
				<Link to='/dashboard/feedback' className={location.pathname === '/dashboard/feedback' ? 'active' : ''} onClick={onClose}>
					<img src={feedbackImg} alt='✍️' /> Feedback
				</Link>
				<Link to='/dashboard/whatsapp-group' className={location.pathname === '/dashboard/whatsapp-group' ? 'active' : ''} onClick={onClose}>
					<img src={whatsappImg} alt='✅' /> Whatsapp
				</Link>
				<Link to='#' onClick={handleSignOut}>
					<img src={doorImg} alt='🚪' /> Logout
				</Link>
			</ul>
			<div className='spacerv-lg'></div>
		</div>
	)
}
