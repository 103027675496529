import { getDatabase, ref, update } from 'firebase/database'
import { loggedIn } from 'lib/firebase'

const db = getDatabase()

export function submitFeedback(feedback) {
	return new Promise((resolve, reject) => {
		loggedIn().then((user) => {
			const dbRef = ref(db, `ambassadors/${user.uid}`)
			update(dbRef, { feedback: feedback }).then(resolve).catch(reject).catch(reject)
		})
	})
}
