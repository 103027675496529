import adobe from 'assets/images/logos/adobe.svg'
import bosch from 'assets/images/logos/bosch.svg'
import dell from 'assets/images/logos/dell.svg'
import apollo from 'assets/images/logos/apollo.svg'
import ibm from 'assets/images/logos/ibm.svg'
import reliance from 'assets/images/logos/reliance.svg'
import tata from 'assets/images/logos/tata.svg'
import texas from 'assets/images/logos/texas.svg'

const sponsors = [
	{
		img: adobe,
		alt: 'Adobe',
	},
	{
		img: dell,
		alt: 'Dell',
	},
	{
		img: reliance,
		alt: 'Reliance',
	},
	{
		img: ibm,
		alt: 'IBM',
	},
	{
		img: tata,
		alt: 'Tata Motors',
	},
	{
		img: bosch,
		alt: 'Bosch',
	},
	{
		img: texas,
		alt: 'Texas Instruments',
	},
	{
		img: apollo,
		alt: 'Apollo',
	},
]

export default function Sponsors() {
	return (
		<div className='sponsors container'>
			{sponsors.map((item, index) => (
				<img key={index} className='sponsor-img' src={item.img} alt={item?.alt} height='80px' width='auto' />
			))}
		</div>
	)
}
