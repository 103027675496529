// FONTS
import 'assets/fonts/inter/inter.css'
import 'assets/fonts/clash-display/clash-display.css'

// COMMON CSS
import 'styles/text-styles.css'
import 'styles/layout.css'
import 'styles/form.css'
import 'styles/app.css'
import 'styles/global.css'
import 'styles/animation.css'

// COMPONENT CSS
import 'components/homepage/homepage.css'
import 'components/homepage/card.css'
import 'components/homepage/page2.css'
import 'components/homepage/page4.css'
import 'components/homepage/hero.css'
import 'components/homepage/benefits.css'
import 'components/homepage/sponsors.css'
import 'components/homepage/ringed-icon.css'

import 'components/contact/contact.css'
import 'components/contact/contact-us.css'

import 'components/leaderboard/leaderboard.css'

import 'components/register/register-form.css'

import 'components/dashboard/dashboard.css'
import 'components/dashboard/side-nav.css'
import 'components/dashboard/referrals.css'
import 'components/dashboard/posters.css'

import 'components/common/nav.css'
import 'components/common/menu.css'
import 'components/common/page-header.css'
import 'components/common/sub-heading.css'
import 'components/common/r-u-ready.css'
import 'components/common/footer.css'

import 'components/developer/developer.css'
import 'components/regclosed/registerclosed.css'

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import ScrollToTop from 'components/common/SrollToTop'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Home from 'pages/home'
import Contact from 'pages/contact'
import Leaderboard from 'pages/leaderboard'
import Register from 'pages/register'
import Dashboard from 'pages/dashboard'
import DeveloperPage from 'components/developer/DeveloperPage'
import RegClosed from 'pages/regclosed'

export default function App() {
	return (
		<Router>
			<ScrollToTop>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/contact' element={<Contact />} />
					<Route path='/leaderboard' element={<Leaderboard />} />
					<Route path='/register' element={<Register />} />
					<Route path='/regclosed' element={<RegClosed/>} />
					<Route path='/dashboard/*' element={<Dashboard />} />
					<Route path='/team' element={<DeveloperPage />} />
				</Routes>
			</ScrollToTop>
			<ToastContainer />
		</Router>
	)
}
