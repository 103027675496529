import { useState, useEffect } from 'react'

export default function YourReferrals({ userDb }) {
	const [referrals, setReferrals] = useState([])

	useEffect(() => {
		if (userDb?.referral) {
			const temp = []
			for (let key in userDb?.referral) {
				let curr = userDb.referral[key]
				temp.push({ key: key, type: curr?.type, value: curr?.value, status: curr?.status, count: curr?.count })
			}
			setReferrals(temp)
		} else setReferrals([])
	}, [userDb])

	return (
		<div className='your-referrals'>
			<h4>Your referrals</h4>

			{referrals.length > 0 ? (
				<div className='your-referrals-table-wrapper'>
					<table className='your-referrals-table'>
						<tbody>
							<tr>
								<th>Task</th>
								<th>Description</th>
								<th>Count</th>
								<th>Status</th>
							</tr>
							{referrals.map((item) => (
								<tr key={item?.key}>
									<td>{item?.type}</td>
									<td>{item?.value}</td>
									<td>{item?.count ? item.count : 1}</td>
									<td>{item?.status}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className='empty'>Nothing here yet</div>
			)}
		</div>
	)
}
