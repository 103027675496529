import { useEffect, useState } from 'react'

import Nav from 'components/common/Nav'
import PageHeader from 'components/common/PageHeader'
import Footer from 'components/common/Footer'
import RegisterForm from 'components/register/RegisterForm'

import pen from 'assets/images/pen.png'

export default function Register() {
	const [params, setPrams] = useState({})

	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search)
		const params = Object.fromEntries(urlSearchParams.entries())
		setPrams(params)
	}, [])

	return (
		<>
			<Nav />
			<PageHeader title={params?.editprofile === 'true' ? 'Edit profile' : 'Register'} icon={pen} />
			<RegisterForm editProfile={params?.editprofile === 'true'} />
			<Footer />
		</>
	)
}
