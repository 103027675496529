import { getDatabase, ref, onValue } from 'firebase/database'
import { loggedIn } from 'lib/firebase'

const db = getDatabase()

export function fetchTasks(callback) {
	const dbRef = ref(db, 'tasks')
	onValue(dbRef, (snapshot) => {
		const data = snapshot.val()
		callback(data)
	})
}

export function fetchNotification(callback) {
	const dbRef = ref(db, 'notification')
	onValue(dbRef, (snapshot) => {
		const data = snapshot.val()
		callback(data)
	})
}

export function fetchUser(callback) {
	loggedIn()
		.then((user) => {
			const dbRef = ref(db, `ambassadors/${user.uid}`)
			onValue(dbRef, (snapshot) => {
				const data = snapshot.val()
				callback(data, dbRef)
			})
		})
		.catch(() => callback(null))
}

export function fetchPoints(callback) {
	loggedIn()
		.then((user) => {
			const dbRef = ref(db, `points/${user.uid}`)
			onValue(dbRef, (snapshot) => {
				const data = snapshot.val()
				callback(data, dbRef)
			})
		})
		.catch(() => callback(null))
}

export function fetchLeaderboard(callback) {
	const dbRef = ref(db, 'leaderboard')
	onValue(dbRef, (snapshot) => {
		const data = snapshot.val()
		callback(data, dbRef)
	})
}
