import { useState, useEffect } from 'react'
import { submitContact } from 'lib/req/contact'
import { toast } from 'react-toastify'
import { fetchUser } from 'lib/req/fetch-dashboard'

import { IoMdCall } from 'react-icons/io'
import { MdEmail } from 'react-icons/md'
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa'
import SubHeading from '../common/SubHeading'

import message from 'assets/images/message.png'
import hand from 'assets/images/hand.png'
import earth from 'assets/images/earth.png'

export default function ContactUs() {
	const [form, setForm] = useState({ name: '', email: '', message: '' })

	useEffect(() => {
		fetchUser((userDb) => {
			if (userDb) setForm({ name: userDb?.name || '', email: userDb?.email || '', message: '' })
		})
	}, [])

	const handleChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value })
	}

	function handleSubmit(e) {
		e.preventDefault()
		submitContact(form)
			.then(() => {
				setForm({ name: '', email: '', message: '' })
				toast.success('Message sent')
			})
			.catch((err) => toast.error('Try again!'))
	}

	return (
		<div className='container'>
			<div className='contact-us'>
				<div className='contact-us-left'>
					<div>
						<SubHeading title='Get in touch' icon={hand} />
						<div className='git-content'>
							<div className='contact'>
								<div className='contact-icon-wrapper'>
									<IoMdCall className='contact-icon' />
								</div>
								<div className='contact-details'>
									<h5>Basil Mohammed P</h5>
									<p>+91 95446 22732</p>
								</div>
							</div>
							<div className='contact'>
								<div className='contact-icon-wrapper'>
									<IoMdCall className='contact-icon' />
								</div>
								<div className='contact-details'>
									<h5>Vishnu Vijay</h5>
									<p>+91 90615 88365</p>
								</div>
							</div>
							<div className='contact'>
								<div className='contact-icon-wrapper'>
									<MdEmail className='contact-icon' />
								</div>
								<div className='contact-details'>
									<h5>Email</h5>
									<p>ragam@nitc.ac.in</p>
								</div>
							</div>
						</div>
					</div>

					<div className='social'>
						<SubHeading title='Social' icon={earth} />
						<div className='social-icons-wrapper'>
							<a href='https://instagram.com/ragam_nitc' target='_blank' rel='noreferrer noopener'>
								<div className='contact-icon-wrapper social-icon'>
									<FaInstagram className='contact-icon' />
								</div>
							</a>
							<a href='https://www.facebook.com/Ragam.nitc/' target='_blank' rel='noreferrer noopener'>
								<div className='contact-icon-wrapper social-icon'>
									<FaFacebook className='contact-icon' />
								</div>
							</a>
							<a href='https://www.linkedin.com/company/ragam-nit-calicut' target='_blank' rel='noreferrer noopener'>
								<div className='contact-icon-wrapper social-icon'>
									<FaLinkedin className='contact-icon' />
								</div>
							</a>
						</div>
					</div>
				</div>

				<form className='contact-us-right' onSubmit={handleSubmit}>
					<SubHeading title='Message' icon={message} />

					<fieldset>
						<label>Name</label>
						<input
							type='text'
							name='name'
							placeholder='Enter your name'
							autoComplete='off'
							required
							value={form.name}
							onChange={handleChange}
						/>
					</fieldset>
					<fieldset>
						<label>Email</label>
						<input
							type='email'
							name='email'
							placeholder='Enter your email'
							autoComplete='off'
							value={form.email}
							onChange={handleChange}
							required
						/>
					</fieldset>
					<fieldset>
						<label>Message</label>
						<textarea placeholder='Enter message' name='message' value={form.message} onChange={handleChange} required></textarea>
					</fieldset>
					<button type='submit' className='btn-secondary'>
						Submit
					</button>
				</form>
			</div>
		</div>
	)
}
