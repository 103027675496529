import { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import { off } from 'firebase/database'

import { loggedIn } from 'lib/firebase'
import { fetchPoints, fetchTasks, fetchUser, fetchNotification } from 'lib/req/fetch-dashboard'
import { validateUserDb } from 'lib/req/register'

import DashboardLoading from './DashboardLoading'
import Referrals from './Referrals'
import Posters from './Posters'
import Feedback from './Feedback'
import WhatsappGroup from './WhatsappGroup.js'

import SideNav from './SideNav'

export default function Dashboard() {
	const [userDb, setUserDb] = useState(null)
	const [points, setPoints] = useState(null)
	const [tasks, setTasks] = useState(null)
	const [notification, setNotification] = useState(null)
	const [showSideNav, setShowSideNav] = useState(false)

	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		let dbRef
		loggedIn()
			.then((user) => {
				fetchUser((userDb, dbRefLocal) => {
					dbRef = dbRefLocal
					setUserDb(userDb)
					const valid = validateUserDb(userDb)
					if (!valid.success) navigate('/register')
					else {
						fetchTasks(setTasks)
						fetchPoints(handlePoints)
						fetchNotification(setNotification)
						if (location.pathname === '/dashboard') navigate('/dashboard/referrals')
					}
				})
			})
			.catch(() => navigate('/'))
		return () => {
			if (dbRef) off(dbRef)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname])

	function handlePoints(points) {
		let totalPoints = 0
		for (let key in points) {
			let temp = parseInt(points[key]?.value)
			if (!isNaN(temp)) totalPoints += temp
		}
		setPoints(totalPoints)
	}

	return (
		<div className='container dashboard-container'>
			<div className='dashboard'>
				{showSideNav && (
					<div className='dashboard-nav-wrapper'>
						<SideNav
							userDb={userDb}
							points={points}
							onClose={() => setShowSideNav(false)}
						/>
					</div>
				)}
				<div className='dashboard-nav-wrapper side-nav-desktop'>
					<SideNav
						userDb={userDb}
						points={points}
						onClose={() => setShowSideNav(false)}
					/>
				</div>

				<div className='dashboard-main'>
					{notification && (
						<div className='notification'>
							<h4 className='notification-header'> Notification </h4>
							<p>{notification.message}</p>
						</div>
					)}
					<Routes>
						<Route path='/' element={<DashboardLoading />} />
						<Route
							path='/referrals'
							element={
								<Referrals
									referrals={tasks?.referrals}
									userDb={userDb}
									onMenuOpen={() => setShowSideNav(true)}
								/>
							}
						/>
						<Route
							path='/posters'
							element={
								<Posters
									posters={tasks?.posters}
									userDb={userDb}
									onMenuOpen={() => setShowSideNav(true)}
								/>
							}
						/>
						<Route
							path='/feedback'
							element={
								<Feedback
									feedback={tasks?.feedback}
									userDb={userDb}
									onMenuOpen={() => setShowSideNav(true)}
								/>
							}
						/>
						<Route
							path='/whatsapp-group'
							element={
								<WhatsappGroup
									whatsapp={tasks?.whatsapp}
									onMenuOpen={() => setShowSideNav(true)}
								/>
							}
						/>
					</Routes>
				</div>
			</div>
			<div className='spacerv-md'></div>
			<div className='spacerv-sm'></div>
		</div>
	)
}
