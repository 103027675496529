import Hero from 'components/homepage/Hero'
import Footer from 'components/common/Footer'
import Page2 from 'components/homepage/Page2'
import Page4 from 'components/homepage/Page4'
import RUReady from 'components/common/RUReady'
import Benefits from 'components/homepage/Benefits'

export default function Home() {
	return (
		<div>
			<Hero />
			<Page2 />
			<Benefits />
			<Page4 />
			<RUReady />
			<Footer />
		</div>
	)
}
