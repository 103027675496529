import {FaGithub, FaInstagram} from 'react-icons/fa'

import christo from 'assets/images/cristo.jpg'
import dz from 'assets/images/vaibhav.jpg'
import akhil from 'assets/images/akhil.jpg'



const Developer = () => {
    return (
        <div className='container'>
            <div className="developer-container">
                <div className="developer-details">
                    <img className='developer-img' src={dz} alt="Vaibhav Gupta" />
                    <p className='developer-name'>Vaibhav Gupta</p>
                    <p className='developer-position'>Front-End</p>
                    <div className="developer-social-wrapper">
                       <a href='https://github.com/DastaanDZ' target='_blank' rel='noopener noreferrer'><FaGithub className='developer-social-icon'/></a> 
                       <a href='https://www.instagram.com/dastaandz/' target='_blank' rel='noopener noreferrer'><FaInstagram className='developer-social-icon'/></a> 
                    </div>
                </div>
                <div className="developer-details">
                    <img className='developer-img' src={christo} alt="Emanuel Cristo" />
                    <p className='developer-name'>Emanuel Cristo</p>
                    <p className='developer-position'>Developer/Designer</p>
                    <div className="developer-social-wrapper">
                       <a href='https://github.com/emanuelchristo' target='_blank' rel='noopener noreferrer'><FaGithub className='developer-social-icon'/></a> 
                       <a href='https://www.instagram.com/emanuel.christo/' target='_blank' rel='noopener noreferrer'><FaInstagram className='developer-social-icon'/></a> 
                    </div>
                </div>
                <div className="developer-details">
                    <img className='developer-img' src={akhil} alt="Akhil" />
                    <p className='developer-name'>Akhil Dev M S</p>
                    <p className='developer-position'>Front-End</p>
                    <div className="developer-social-wrapper">
                       <a href='https://github.com/akhil09dev' target='_blank' rel='noopener noreferrer'><FaGithub className='developer-social-icon'/></a> 
                       <a href='https://www.instagram.com/akhil._.sasidharan/' target='_blank' rel='noopener noreferrer'><FaInstagram className='developer-social-icon'/></a> 
                    </div>
                </div>
            </div>
            <div className='spacerv-md'></div>
			<div className='spacerv-sm'></div>
        </div>
    )
}

export default Developer
