import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { loggedIn, signIn } from 'lib/firebase'

import { BiMenu } from 'react-icons/bi'
import Menu from './Menu'

import ragamLogo from 'assets/images/ragam-white.svg'

export default function Nav() {
	const [user, setUser] = useState(null)
	const [showMenu, setShowMenu] = useState(false)

	const navigate = useNavigate()

	useEffect(() => {
		loggedIn().then(setUser).catch(setUser)
	}, [])

	function handleSignIn() {
		signIn()
			.then(() => navigate('/dashboard'))
			.catch(console.error)
	}

	// function handleSignOut() {
	// 	signOut()
	// 		.then(() => navigate('/'))
	// 		.catch(console.error)
	// }

	return (
		<nav className='navbar'>
			<div className='logo-wrapper'>
				<BiMenu className='menu-icon' onClick={() => setShowMenu(true)} />
				<Link to='/'>
					<img src={ragamLogo} alt='Ragam 2023' className='nav-logo' />
				</Link>
			</div>

			<div className='nav-right'>
				<ul className='nav-links'>
					<li>
						<Link to='/' className='nav-text'>
							Home
						</Link>
					</li>
					<li>
						<Link to='/leaderboard' className='nav-text'>
							Leaderboard
						</Link>
					</li>
					<li>
						<Link to='/contact' className='nav-text'>
							Contact
						</Link>
					</li>
					<li>
						{!user && (
							<div className='sign-in-desktop' onClick={handleSignIn}>
								Sign in
							</div>
						)}
						{user && (
							<div className='sign-out-desktop'>
								{/* <span onClick={handleSignOut}>Sign out</span> */}
								<Link to='/dashboard'>
									<div className='avatar' style={{ backgroundImage: `url(${user?.photoURL})` }}></div>
								</Link>
							</div>
						)}
					</li>
				</ul>

				{!user && (
					<div className='sign-in-mobile' onClick={handleSignIn}>
						Sign in
					</div>
				)}
				{user && (
					<div className='sign-out-mobile'>
						{/* <span onClick={handleSignOut}>Sign out</span> */}
						<Link to='/dashboard'>
							<div className='avatar' style={{ backgroundImage: `url(${user?.photoURL})` }}></div>
						</Link>
					</div>
				)}
			</div>
			<Menu show={showMenu} onClose={() => setShowMenu(false)} />
		</nav>
	)
}
