import { useState, useEffect } from 'react'
import { fetchLeaderboard } from 'lib/req/fetch-dashboard'

import DashboardLoading from 'components/dashboard/DashboardLoading'

export default function Leaderboard() {
	const [leaderboard, setLeaderboard] = useState(null)

	useEffect(() => {
		fetchLeaderboard((data) => {
			let temp = []
			for (let key in data) temp.push({ index: key, name: data[key].name, points: data[key].point })
			temp.sort((a, b) => (a.points < b.points ? 1 : -1))
			for (let i = 0; i < temp.length; i++) temp[i].rank = i + 1
			setLeaderboard(temp)
		})
	}, [])

	return (
		<div className='container'>
			<div className='leaderboard'>
				{leaderboard && leaderboard?.length > 0 && (
					<div className='participants-wrapper'>
						<div className='participants-header'>
							<span className='p-rank'>Rank</span>
							<span className='p-name'>Name</span>
							<span className='p-points'>Points</span>
						</div>
						{leaderboard.map((item, index) => (
							<Participant key={index} rank={item?.rank} name={item?.name} points={item?.points} />
						))}
					</div>
				)}

				{leaderboard && leaderboard?.length === 0 && <div className='empty empty-leaderboard'>Nothing here yet</div>}

				{!leaderboard && (
					<div className='leaderboard-loading-wrapper'>
						<DashboardLoading />
					</div>
				)}
			</div>
		</div>
	)
}

function Participant({ rank, name, points }) {
	return (
		<div className='participant'>
			<span className='p-rank'>{rank}</span>
			<span className='p-name'>{name}</span>
			<span className='p-points'>{points}</span>
		</div>
	)
}
