import { useNavigate } from 'react-router-dom'
import { signIn } from 'lib/firebase'
import CountUp from 'react-countup'

const HeroText = () => {
	const navigate = useNavigate()

	function handleSignup() {
		signIn()
			.then(() => navigate('/dashboard'))
			.catch(console.error)
	}

	return (
		<div className='hero-text container'>
			<h1 className='hero-heading'>
				Campus <br />
				Ambassador
			</h1>
			<p className='hero-tagline'>Be the emissary of Ragam 2023</p>
			<div className='hero-cta-wrapper'>
				<button onClick={handleSignup} className='btn-primary'>
					Sign up
				</button>
				<a href='#explore'>
					<button className='btn-outline'>Explore</button>
				</a>
			</div>
			<div className='hero-stats'>
			<div className='stat-wrapper'>
					<CountUp className='stat-num' end={100} duration={1.5} delay={0.5} suffix='+' useEasing={true} />
					<p>Events</p>
				</div>
				<div className='stat-wrapper'>
					<CountUp className='stat-num' end={150} duration={1.5} delay={0.5} suffix='+' useEasing={true} />
					<p>Sponsors</p>
				</div>
				<div className='stat-wrapper'>
					<CountUp className='stat-num' end={25} duration={1.5} delay={0.5} suffix='+' useEasing={true} />
					<p>Workshops</p>
				</div>
			</div>
		</div>
	)
}
export default HeroText
