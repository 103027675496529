import Sponsors from './Sponsors'
import Nav from 'components/common/Nav'
import HeroText from './HeroText'

import arrowDown from 'assets/images/arrow-down.svg'

export default function Hero() {
	return (
		<div className='hero'>
			<div className='hero-nav-wrapper'>
				<Nav />
			</div>

			<div className='spacerv-lg'></div>
			<div className='hero-content'>
				<HeroText />
			</div>
			<div className='spacerv-lg'></div>

			<div className='hero-sponsors-wrapper'>
				<Sponsors />
			</div>

			<img className='hero-arrow' alt='' src={arrowDown} />
		</div>
	)
}
