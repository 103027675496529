import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'

export default function PosterCard({ imageLink, heading, writeup }) {
	return (
		<div className='poster-card'>
			<div className='poster-card-image' style={{ backgroundImage: `url('${imageLink}')` }}></div>
			<div className='poster-card-content'>
				<h4>{heading}</h4>
				<p style={{whiteSpace:"pre-line"}}>{writeup}</p>
				<div className='poster-card-buttons-wrapper'>
					<a href={imageLink} target='_blank' rel='noopener noreferrer' className='download-poster-button' download>
						<button className='btn-secondary'>Download poster</button>
					</a>
					<CopyToClipboard text={writeup} onCopy={() => toast('Copied!')}>
						<button className='btn-outline'>Copy writeup</button>
					</CopyToClipboard>
				</div>
			</div>
		</div>
	)
}
