import React from 'react'
import Developer from '../developer/Developer'
import Nav from 'components/common/Nav'
import PageHeader from 'components/common/PageHeader'
import Footer from 'components/common/Footer'
import devimg from 'assets/images/developer-image.png'
// import './developer.css'

const DeveloperPage = () => {
    return (
        <>
            <Nav />
            <PageHeader title='Team' icon={devimg}/>
			<Developer/>
			<Footer />
        </>
    )
}

export default DeveloperPage
