import { Link } from 'react-router-dom'
import { IoMdClose } from 'react-icons/io'

import ragamLogo from 'assets/images/ragam-white.svg'
import cup from 'assets/images/leaderboard.png'
import telephone from 'assets/images/contact.png'
import home from 'assets/images/home.png'

export default function Menu({ show, onClose }) {
	return (
		<div className={`menu ${show ? 'menu-visible' : 'menu-hidden'}`}>
			<div className='navbar'>
				<div className='logo-wrapper'>
					<IoMdClose className='menu-icon' onClick={onClose} />
					<Link to='/'>
						<img src={ragamLogo} alt='Ragam 2023' className='nav-logo' />
					</Link>
				</div>
			</div>

			<ul className='menu-links' onClick={onClose}>
				<li>
					<Link to='/'>
						<img src={home} alt='🏡' />
						Home
					</Link>
				</li>
				<li>
					<Link to='/leaderboard' onClick={onClose}>
						<img src={cup} alt='🏆' />
						Leaderboard
					</Link>
				</li>
				<li>
					<Link to='/contact' onClick={onClose}>
						<img src={telephone} alt='☎️' />
						Contact
					</Link>
				</li>
			</ul>
		</div>
	)
}
