import hexa from 'assets/images/eligibility.svg'

export default function Page4() {
	return (
		<>
			<div className='page-4'>
				<div className='page-4-content'>
					<p>Any student currently pursuing education in an established institute who wishes to participate in Ragam may apply.</p>
					<p>Applicants having good interpersonal and communication skills with previous experience will be given preference.</p>
				</div>

				<div className='page-4-title-wrapper'>
					<div className='page-4-title-content'>
						<h2 className='text-eligibility'>Eligibility</h2>
						<img src={hexa} alt='' className='page-4-title-image' />
					</div>
				</div>
			</div>
		</>
	)
}
