import { useNavigate } from 'react-router-dom'
import { signIn } from 'lib/firebase'

import vic from 'assets/images/victory.png'

export default function RUReady() {
	const navigate = useNavigate()

	function handleSignup() {
		signIn()
			.then(() => navigate('/dashboard'))
			.catch(console.error)
	}

	return (
		<div className='container'>
			<div className='spacerv-md'></div>
			<div className='spacerv-sm'></div>
			<div className='r-u-ready'>
				<div className='left'>
					<img src={vic} alt='✌️' className='vic-icon' />
					<div className='spacerh-xs'></div>
					<div className='r-u-ready-text'>
						<h3>Are you ready?</h3>
						<p>
							To be a part of the biggest cultural
							<br /> fest in South India
						</p>
					</div>
				</div>
				<button className='btn-primary r-u-ready-btn' onClick={handleSignup}>
					Sign up
				</button>
			</div>
			<div className='spacerv-md'></div>
			<div className='spacerv-sm'></div>
		</div>
	)
}
