import { BiMenu } from 'react-icons/bi'

import SubHeading from '../common/SubHeading'
import NotActive from 'components/dashboard/NotActive'
import DashboardLoading from 'components/dashboard/DashboardLoading'

import whatsappImg from 'assets/images/whatsapp.png'

export default function WhatsappGroup({ whatsapp, onMenuOpen }) {
	return (
		<>
			<div className='feedback'>
				<div className='dashboard-main-heading-wrapper'>
					<BiMenu className='dashboard-menu-icon' onClick={onMenuOpen} />
					<SubHeading title='Whatsapp' icon={whatsappImg} />
				</div>

				{whatsapp?.active && (
					<div className='dashboard-main-content'>
						<div className='feedback-content'>
							<a href={whatsapp?.groupURL} target='_blank' rel='noopener noreferrer'>
								<button className='btn-secondary'>Join our WhatsApp group</button>
							</a>
						</div>
					</div>
				)}

				{!whatsapp?.active && whatsapp && <NotActive />}
			</div>
			{!whatsapp && <DashboardLoading />}
		</>
	)
}
