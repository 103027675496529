import { useState, useEffect } from 'react'
import { BiMenu } from 'react-icons/bi'

import SubHeading from '../common/SubHeading'
import NotActive from './NotActive'
import DashboardLoading from 'components/dashboard/DashboardLoading'
import YourReferrals from 'components/dashboard/YourReferrals'

import referalImg from 'assets/images/referal.png'

export default function Referals({ referrals, userDb, onMenuOpen }) {
	const [refId, setRefId] = useState('')

	useEffect(() => {
		setRefId(userDb?.ref_id)
	}, [userDb])

	return (
		<>
			<div className='referals'>
				<div className='dashboard-main-heading-wrapper'>
					<BiMenu className='dashboard-menu-icon' onClick={onMenuOpen} />
					<SubHeading title='Referrals' icon={referalImg} />
				</div>

				{referrals?.active && (
					<div className='dashboard-main-content'>
						<div className='referral-code'>
							<div className='code'>Code</div>
							<div>{refId || 'Will be updated soon'}</div>
						</div>

						<div className='spacerv-sm'></div>

						<div className='referrals-points'>
							<h4>Point per Person</h4>
							<p>Refer friends to Ragam events, workshops and lectures using your unique referrel code to receive points</p>

							<table>
								<tbody>
									<tr>
										<td>Workshop</td>
										<td>2500</td>
									</tr>
									<tr>
										<td>Hackathons</td>
										<td>1500</td>
									</tr>
									<tr>
										<td>Lectures</td>
										<td>1250</td>
									</tr>
									<tr>
										<td>Events</td>
										<td>1250</td>
									</tr>
									<tr>
										<td>Registration</td>
										<td>500</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div className='spacerv-sm'></div>

						<YourReferrals userDb={userDb} />
					</div>
				)}

				{referrals && !referrals?.active && <NotActive />}
			</div>
			{!referrals && <DashboardLoading />}
		</>
	)
}
