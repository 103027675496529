import { useState, useEffect } from 'react'
import { BiMenu } from 'react-icons/bi'

import SubHeading from '../common/SubHeading'
import PosterCard from './PosterCard'
import PostersGuide from './PostersGuide'
import NotActive from 'components/dashboard/NotActive'
import DashboardLoading from 'components/dashboard/DashboardLoading'

import posterImg from 'assets/images/posters.png'

export default function Posters({ userDb, posters: postersDb, onMenuOpen }) {
	const [posters, setPosters] = useState([])
	const [refId, setRefId] = useState('')

	useEffect(() => {
		setRefId(userDb?.ref_id)
	}, [userDb])

	useEffect(() => {
		console.log(refId)
		const temp = []
		const regex = /https:\/\/.*ragam.co.in\/(.*)\n/
		for (let key in postersDb?.posters) {
			let poster = { ...postersDb?.posters[key] }
			if (refId) {
				let newriteup = poster.writeup.replace(regex, (match) => {
					return match.slice(0, -1) + '?refCode=' + refId
				})
				poster.writeup = newriteup
			}
			if (!poster.hidden) temp.push(poster)
		}
		temp.reverse()
		setPosters(temp)
	}, [postersDb, refId])

	return (
		<>
			<div className='posters'>
				<div className='dashboard-main-heading-wrapper'>
					<BiMenu className='dashboard-menu-icon' onClick={onMenuOpen} />
					<SubHeading title='Posters' icon={posterImg} />
				</div>

				{postersDb?.active && (
					<>
						<PostersGuide userDb={userDb} />
						<div className='dashboard-main-content'>
							{posters.length > 0 ? (
								posters.map((item, index) => (
									<PosterCard
										key={index}
										imageLink={item?.image}
										heading={item?.title}
										writeup={item?.writeup}
									/>
								))
							) : (
								<div className='empty'>Nothing here yet</div>
							)}
						</div>
					</>
				)}

				{!postersDb?.active && postersDb && <NotActive />}
			</div>
			{!postersDb && <DashboardLoading />}
		</>
	)
}
