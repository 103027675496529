import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

export default function ScrollToTop(props) {
	const [prevPath, setPrevPath] = useState('')

	const location = useLocation()
	useEffect(() => {
		if (!isInsideDashboard()) window.scrollTo(0, 0)
		setPrevPath(location.pathname)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	function isInsideDashboard() {
		const pattern = /(\/dashboard\/)[\w]+/
		return prevPath.match(pattern) && location.pathname.match(pattern)
	}

	return <>{props.children}</>
}
