import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { off } from 'firebase/database'

import { loggedIn } from 'lib/firebase'
import { register, validateUserDb } from 'lib/req/register'
import { fetchUser } from 'lib/req/fetch-dashboard'

export default function RegisterForm({ editProfile }) {
	const [user, setUser] = useState({})
	const [form, setForm] = useState({
		name: '',
		college: '',
		branch: '',
		year: '',
		experience: 'no',
		email: '',
		phone: '',
	})
	const navigate = useNavigate()

	useEffect(() => {
		let dbRef
		loggedIn()
			.then((user) => {
				setUser(user)
				fetchUser((userDb, dbRefLocal) => {
					// If not already in db then reg closed.
					if (!validateUserDb(userDb).success) navigate('/regclosed')

					dbRef = dbRefLocal
					setForm({
						name: userDb?.name || '',
						college: userDb?.college || '',
						branch: userDb?.branch || '',
						year: userDb?.year || '',
						experience: userDb?.experience || 'no',
						email: user.email,
						phone: userDb?.phone || '',
					})
				})
			})
			.catch(() => navigate('/'))
		return () => off(dbRef)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value })
	}

	function handleSubmit(e) {
		e.preventDefault()
		const userDb = { ...form, photo: user?.photoURL }
		const valid = validateUserDb(userDb)
		if (!valid.success) toast.error(valid.error)
		else
			register(user, userDb)
				.then(() => {
					toast.success('Registration successful')
					navigate('/dashboard')
				})
				.catch((err) => toast.error('Try again!'))
	}

	return (
		<div className='container'>
			<form className='register-form' onSubmit={handleSubmit}>
				<div className='register-form-avatar-wrapper'>
					<div
						className='register-form-avatar'
						style={{ backgroundImage: `url(${user?.photoURL})` }}
					></div>
				</div>
				<fieldset>
					<label>Name*</label>
					<input
						type='text'
						name='name'
						placeholder='Enter your name'
						value={form.name}
						onChange={handleChange}
						required
					/>
				</fieldset>
				<fieldset>
					<label>College*</label>
					<input
						type='text'
						name='college'
						placeholder='Enter your college'
						value={form.college}
						onChange={handleChange}
					/>
				</fieldset>
				<fieldset>
					<label>Branch</label>
					<input
						type='text'
						name='branch'
						placeholder='Enter your branch'
						value={form.branch}
						onChange={handleChange}
					/>
				</fieldset>
				<fieldset>
					<label>Year of study</label>
					<input
						type='text'
						name='year'
						placeholder='Enter your year of study'
						value={form.year}
						onChange={handleChange}
					/>
				</fieldset>
				<fieldset>
					<label>Have you been a CA before?*</label>
					<select name='experience' value={form.experience} onChange={handleChange}>
						<option value='no'>No</option>
						<option value='yes'>Yes</option>
					</select>
				</fieldset>
				<fieldset>
					<label>Email*</label>
					<input
						type='email'
						name='email'
						placeholder='Enter your email'
						value={form.email}
						onChange={handleChange}
						required
						disabled
					/>
				</fieldset>
				<fieldset>
					<label>Whatsapp no.*</label>
					<input
						type='text'
						name='phone'
						placeholder='Enter your whatsapp no.'
						value={form.phone}
						onChange={handleChange}
						required
					/>
				</fieldset>
				<button className='btn-secondary register-form-submit'>
					{editProfile ? 'Save changes' : 'Register'}
				</button>
			</form>
			<div className='spacerv-md'></div>
			<div className='spacerv-sm'></div>
		</div>
	)
}
