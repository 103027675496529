import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { BiMenu } from 'react-icons/bi'

import { submitFeedback } from 'lib/req/feedback'

import SubHeading from '../common/SubHeading'
import NotActive from 'components/dashboard/NotActive'
import DashboardLoading from 'components/dashboard/DashboardLoading'

import feedbackImg from 'assets/images/feedback.png'

export default function Feedback({ userDb, feedback, onMenuOpen }) {
	const [feedbackForm, setFeedbackForm] = useState(userDb?.feedback || '')

	useEffect(() => {
		setFeedbackForm(userDb?.feedback || '')
	}, [userDb])

	function handleSubmit() {
		submitFeedback(feedbackForm)
			.then(() => toast.success('Feedback submitted'))
			.catch(() => toast.error('Try again!'))
	}

	return (
		<>
			<div className='feedback'>
				<div className='dashboard-main-heading-wrapper'>
					<BiMenu className='dashboard-menu-icon' onClick={onMenuOpen} />
					<SubHeading title='Feedback' icon={feedbackImg} />
				</div>

				{feedback?.active && (
					<div className='dashboard-main-content'>
						<div className='feedback-content'>
							<p>We'd love to know your thoughts</p>
							<textarea
								name='feedback'
								placeholder='Enter your message'
								value={feedbackForm}
								onChange={(e) => setFeedbackForm(e.target.value)}
							/>
							<button className='btn-secondary' onClick={handleSubmit}>
								Submit
							</button>
						</div>
					</div>
				)}

				{!feedback?.active && feedback && <NotActive />}
			</div>
			{!feedback && <DashboardLoading />}
		</>
	)
}
